'use strict';

angular.module('webPartnerPortalApp')
.controller('NavbarController', ['$scope', 'appConfig', 'EventDispatchingSvc', function($scope, appConfig, EventDispatchingSvc){
    var NavbarControllerVM = this;
    NavbarControllerVM.assets = appConfig.assets;
    angular.extend(NavbarControllerVM, new EventDispatchingSvc($scope));

    var capitalise = function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    NavbarControllerVM.listen('access:login', function(evt, response){
      NavbarControllerVM.logInUserName = capitalise(response.first_name)+' '+capitalise(response.last_name);
      NavbarControllerVM.role = response.role;
    });
}]);
